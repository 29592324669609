<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-body">
        <div
          class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          "
          style="align-items: flex-start"
        >
          <div class="searchbox" style="width: 100%;margin-bottom: 10px;">
            <div>
              <div title="姓名" class="searchboxItem searchboxItems">
                <span class="itemLabel">姓名:</span>
                <el-input
                  v-model="ruleForm.userName"
                  type="text"
                  size="small"
                  placeholder="请输入姓名"
                  clearable
                  style="flex: 1"
                />
              </div>
              <div title="手机号" class="searchboxItem searchboxItems">
                <span class="itemLabel">手机号:</span>
                <el-input
                  v-model="ruleForm.mobile"
                  type="text"
                  size="small"
                  placeholder="请输入手机号"
                  clearable
                  style="flex: 1"
                />
              </div>
              <div title="学习进度" class="searchboxItem searchboxItems">
                <span class="itemLabel">学习进度:</span>
                  <el-select
                  size="small"
                  v-model="ruleForm.completeState"
                  placeholder="请选择学习进度"
                  clearable
                >
                  <el-option
                    v-for="item in completeStateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="searchbox" style="width: 100%">
            <div>
              <div title="加入时间" class="searchboxItem searchboxItems">
                <span class="itemLabel">加入时间:</span>
                <el-date-picker
                  clearable
                  size="small"
                  v-model="ruleForm.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </div>
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="
                  () => {
                    getData();
                  }
                "
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="Exportstudent"
                >导入学员</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                min-width="100"
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                min-width="150"
              />
              <el-table-column
                label="加入时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="150"
              />
              <el-table-column
                label="上次学习时间"
                align="left"
                show-overflow-tooltip
                prop="lastStudyTime"
                min-width="150"
              />
              <el-table-column
                label="任务总时长"
                align="left"
                show-overflow-tooltip
                prop="totalDurationStr"
                min-width="100"
              />
              <el-table-column
                label="已学时长"
                align="left"
                show-overflow-tooltip
                prop="studyDurationStr"
                min-width="150"
              />
              <el-table-column
                label="学习进度"
                align="left"
                show-overflow-tooltip
                prop="studyStateStr"
                min-width="100"
              />
              <el-table-column
                label="操作"
                align="center"
                width="100"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="deleteproject(scope.row)"
                      >移除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="trainTaskInfo.projectName + '学员导入'"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span
              v-for="(item, index) in templatelist"
              :key="index"
              style="
                margin-top: 20px;
                margin-right: 20px;
                color: #409eff;
                cursor: pointer;
              "
              @click="downloadExcelgs(item.templatePath)"
            >
              {{ item.templateName }}
            </span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size: 12px; width: 80%"
                  >浏览</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top: 10px; height: 35px"
                :disabled="doExportInDisabled"
                @click="doExport"
                >开始导入</el-button
              >
            </div>
            <div style="margin-top: 10px" v-if="progressVal > 0">
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="progressVal"
              ></el-progress>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>学员总数量:{{ totalNum }}人;</span>
            <span>成功:{{ correctNum }}人;</span>
            <span>失败:{{ errorNum }}人;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top: 15px; height: 35px"
              size="mini"
              :disabled="errorNum == '0'"
              @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classListCtr",
  props:['projectId'],
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      ruleForm: {
        userName: "",//姓名
        mobile: "",//手机号
        createTime: "",//加入时间
        completeState: "",//学习进度
      },
      completeStateList:[],//学习进度list
      // 导入
      centerDialogVisible:false,
      totalNumlb: 0,
      correctNumlb: 0,
      errorNumlb: 0,
      progressVal: 0,
      batchId: "",
      templatelist: "", // 模板数据
      fileName: "",
      fileKey: "",
      excelUrl: "",
      importType: "",
      doExportInDisabled: false, //开始导入按钮禁用
      trainTaskInfo:{}
    };
  },
  created() {
    this.getInfo()
    this.getTableHeight();
    this.getcompleteStateList()
    this.getTemplate(this.projectId);
  },
  computed: {},
  mounted() {},
  methods: {
    //获取培训任务详情
    getInfo() {
      this.$post("/biz/et/trainTask/infoTrainTask", {projectId: this.projectId}).then((res) => {
        this.trainTaskInfo = res.data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId:this.projectId
      };
      // 姓名
      if (this.ruleForm.userName) {
        params.userName = this.ruleForm.userName.trim();
      }
      // 手机号
      if (this.ruleForm.mobile) {
        params.mobile = this.ruleForm.mobile;
      }
      // 加入时间
      if (this.ruleForm.createTime&&this.ruleForm.createTime.length===2) {
        params.startDate = this.ruleForm.createTime[0];
        params.endDate = this.ruleForm.createTime[1];
      }
      // 学习进度
      if (this.ruleForm.completeState) {
        params.completeState = this.ruleForm.completeState;
      }
      this.doFetch(
        {
          url: "/biz/et/trainTask/user/etTrainTaskUserList",
          params,
          pageNum,
        },
        true
      );
    },
    // 学习进度list
    getcompleteStateList(){
      const completeStateList = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in completeStateList) {
        list.push({
          value: key,
          label: completeStateList[key],
        });
      }
      this.completeStateList = list;
    },
    //删除
    deleteproject(row) {
      this.doDel({
        url: "/biz/et/trainTask/user/etTrainTaskDeleteUser",
        msg: "你确定要移除该学员吗？",
        ps: {
          type: "post",
          data: {
            userId:row.userId,
            projectId:this.projectId
          },
        },
      });
    },
    /* 导入 */
    Exportstudent() {
      this.totalNum = "0";
      this.errorNum = "0";
      this.correctNum = "0";
      this.batchId = "";
      let day = this.trainTaskInfo.endDate.slice(0, 10);
      let curDay = this.$moment().format("YYYY/MM/DD");
      if (curDay > day) {
        this.$alert("已过报名截止日期", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
        });
      } else {
        if (this.trainTaskInfo.courseCount == 0) {
          this.$confirm("您的培训任务还未配置课程,请先配置课程！", "提示", {
            confirmButtonText: "配置课程",
            cancelButtonText: "取消",
            confirmButtonClass: "confirmButtonClass",
            type: "warning",
          })
          .then(() => {
            this.$emit('tabChange',{name:'second'})
          })
          .catch(() => {});
        } else {
          this.centerDialogVisible = true;
        }
      }
    },
    downloadExcelgs(templatePath) {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = templatePath;
      let templateNames = templatePath.slice(8);
      console.log(templateNames);
      link.setAttribute("download", templateNames);
      document.body.appendChild(link);
      link.click();
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否将此表中学员绑定培训任务?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.doExportInDisabled = true;
            if (this.fileKey) {
              const parmar = {
                projectId: this.projectId,
                excelUrl: this.fileKey,
              };
              this.$post("/biz/project/user/v2/import", parmar, 5000)
                .then((res) => {
                  if (res.status == 0) {
                    if (res.status == 0) {
                      console.log(res.data);
                      this.doProgress(res.data, this.projectId);
                    }
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //获取导入进度
    /* /biz/projectUser/importProgress */
    doProgress(batchId, projectId) {
      this.$post(
        "/biz/project/user/v2/import/progress",
        { batchId, projectId },
        5000
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId, projectId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/project/user/v2/error/export",
          { batchId: this.batchId, projectId: this.projectId },
          5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.newvalues = [];
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0;
      this.doExportInDisabled = false;
      this.getData();
    },
    getTemplate(projectId) {
      this.$post("/biz/project/user/v2/template", {
        projectId,
      }).then((ret) => {
        this.templatelist = ret.data || [];
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 80 + 80 + 0.675 * 16 + 6;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.framePage{padding: 0;}
.framePage .framePage-body{padding-top:0 ;}
.searchbox > div .el-input {
  flex: 1;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}

</style>
<style lang="less" scope>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.Classitem {
  .el-form-item__content {
    display: flex;
  }
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
.studentNum {
  margin: 20px 0 10px;
  span {
    margin: 0 10px;
  }
}
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>

