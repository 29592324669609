<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goHerf()">培训任务列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ projectName }}-详情</a
          >
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <el-tabs
          v-model="activeName"
          :before-leave="beforeTabLeave"
          @tab-click="tabChange"
        >
          <el-tab-pane label="课程列表" name="second">
            <CourseList ref="second" :projectId="projectId" v-if="activeName=='second'" />
          </el-tab-pane>
          <el-tab-pane label="学员列表" name="three">
            <!-- 普通学员列表 -->
            <StudentList ref="three" :projectId="projectId" @tabChange="tabChange" v-if="activeName=='three'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import CourseList from "@/views/CustomPrograms/trainingList/CourseList.vue";
import StudentList from "@/views/CustomPrograms/trainingList/StudentList.vue";
import { mapGetters } from "vuex";
export default {
  name: "ClassdetailsList",
  components: {
    CourseList,
    StudentList,
  },
  mixins: [List],
  data() {
    return {
      activeName: "",
      projectName: "",
    };
  },
  mounted() {
    this.init()
  },
  watch: {
    
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    init() {
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
        this.projectId = query.projectId;
        this.projectName = query.projectName;
      }
    },
    tabChange(tab) {
      this.activeName = tab.name;
    },
    beforeTabLeave(newName) {
      // this.$router.replace({
      //   path: this.$route.path,
      //   query: { active: newName },
      // });
    },
    goHerf() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
}
#pane-first {
  display: flex;
  flex: 1;
  overflow: hidden;
}
</style>
<style lang="less">
.framePage-tabs {
  .el-tabs {
    width: 100%;
  }
  .el-tabs__content {
    overflow-y: auto;
  }
}
</style>
