<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-body">
        <div
          class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          "
          style="align-items: flex-start"
        >
          <div class="searchbox" style="width: 100%;">
              <el-button
                type="primary"
                class="bgc-bv"
                round
                :disabled="trainTaskInfo.auditState=='30'"
                @click="handleAdd"
                >关联课程</el-button
              >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                min-width="150"
                fixed="left"
              />
              <el-table-column
                label="课件数"
                align="left"
                show-overflow-tooltip
                prop="coursewareCount"
                min-width="100px"
              />
              <el-table-column
                label="视频总时长"
                align="left"
                show-overflow-tooltip
                prop="kpointDurationStr"
                min-width="100px"
              />
              <el-table-column
                label="学员总数"
                align="left"
                show-overflow-tooltip
                prop="userCount"
                min-width="100px"
              />
              <el-table-column
                label="已学人数"
                align="left"
                show-overflow-tooltip
                prop="etStudyUserCount"
                min-width="100px"
              />
              <el-table-column
                label="完成人数"
                align="left"
                show-overflow-tooltip
                prop="completeUserCount"
                min-width="100px"
              />
              <el-table-column
                label="关联时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="100px"
              />
              <el-table-column
                label="操作"
                align="center"
                width="200px"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="seeDetails(scope.row)"
                      >查看</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="deleteproject(scope.row)"
                      >移除</el-button
                    >
                    <el-button
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="Setexamination(scope.row)"
                      v-if="!scope.row.paperId"
                      >设置结业考试</el-button
                    >
                    <el-button
                      v-else
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      :disabled="scope.row.paperSource == '30'"
                      @click="Setexamination(scope.row)"
                      >查看结业考试</el-button
                    >
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="结业考试"
      :visible.sync="dialogExamination"
      v-if="dialogExamination"
      width="600px"
      top="15%"
      :center="true"
      :before-close="doClose"
    >
      <div style="display: flex; flex-direction: column">
        <div>
          <span>考试名称：</span>
          <span>{{ paperName }}</span>
        </div>
        <div style="padding-top: 15px">
          <span>通过分数：</span>
          <span>{{ paperScore }}分</span>
        </div>
        <div style="padding-top: 15px">
          <span>考试时间：</span>
          <span v-if="paperStartTime"
            >{{ paperStartTime | momentWu }}至{{
              paperEndTime | momentWu
            }}</span
          >
          <span v-else>未设置考试时间</span>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: space-between"
      >
        <div>
          <el-button @click="dialogExamination = false">取消</el-button>
        </div>
        <div class="btnBox">
          <el-button
            class="bgc-bv"
            @click="Examinationedit"
            >修改</el-button
          >
          <el-button
            @click="paperdelete"
            >删除</el-button
          >
        </div>
      </span>
    </el-dialog>
    <Mytestpaper ref="Mytestpaper" @eventBus="tableBack" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import Mytestpaper from "@/views/CustomPrograms/trainingList/Mytestpaper";
import { resetKeepAlive } from "@/utils/common";  
export default {
  name: "CourseList",
  props:['projectId'],
  components: {
    Empty,
    PageNum,
    Mytestpaper,
  },
  mixins: [List],
  data() {
    return {
      dialogExamination: false,
      paperTotalScore: "",
      paperName: "",
      paperScore: "",
      paperStartTime: "",
      paperEndTime: "",
      projectCourseId: "",
      paperId: "",
      trainTaskInfo:{}
    };
  },
  created() {
    this.getInfo()
    this.getTableHeight();
  },
  mounted() {},
  methods: {
    //获取培训任务详情
    getInfo() {
      this.$post("/biz/et/trainTask/infoTrainTask", {projectId: this.projectId}).then((res) => {
        this.trainTaskInfo = res.data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
      };
      this.doFetch({
        url: "/biz/et/trainTask/course/etTrainTaskCourseList",
        params,
        pageNum,
      });
    },
    // 创建课程、查看编辑课程
    handleAdd() {
      this.$router.push({
        path: "/web/CustomPrograms/trainingList/addQyCourse",
        query: {
          projectId: this.projectId,
        },
      });
    },
    // 设置课后考试
    Setexamination(row) {
      if (row.paperId) {
        this.dialogExamination = true;
        this.paperScore = row.paperScore;
        this.paperName = row.paperName;
        this.paperStartTime = row.paperStartTime;
        this.paperEndTime = row.paperEndTime;
        this.projectCourseId = row.projectCourseId;
        this.paperTotalScore = row.paperTotalScore;
        this.paperId = row.paperId;
      } else {
        this.$refs.Mytestpaper.showPopUp(
          row.paperTotalScore,
          row.projectCourseId,
          row.paperScore,
          row.paperName,
          row.paperId,
          row.paperStartTime,
          row.paperEndTime
        );
      }
    },
    Examinationedit() {
      this.$refs.Mytestpaper.showPopUp(
        this.paperTotalScore,
        this.projectCourseId,
        this.paperScore,
        this.paperName,
        this.paperId,
        this.paperStartTime,
        this.paperEndTime
      );
    },
    tableBack() {
      this.dialogExamination = false
    },
    paperdelete() {
      this.$confirm("是否删除结业考试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "confirmButtonClass",
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/et/trainTask/course/configureProjectCourseDeletePaper", {
            projectCourseId: this.projectCourseId,
          })
            .then((ret) => {
              this.dialogExamination = false;
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.getData(-1);
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {});
    },
    doClose() {
      this.dialogExamination = false;
    },
    //删除
    deleteproject(row) {
      this.doDel({
        url: "/biz/et/trainTask/course/etTrainTaskCourseDelete",
        msg: "你确定移除该课程吗？",
        ps: {
          type: "post",
          data: { 
            projectCourseId:row.projectCourseId,
          },
        },
      });
    },
    //详情
    seeDetails(row) {
      this.$router.push({
        path: "/web/CustomPrograms/trainingList/seeClassMinsCtr",
        query: {
          projectId: this.projectId,
          courseId: row.courseId,
          projectCourseId: row.projectCourseId,
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 80 + 0.675 * 16 + 6 + 60;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.el-table__body-wrapper {
  z-index: 2;
}
</style>
<style lang="less" scoped>
.framePage{padding: 0;}
.framePage .framePage-body{padding-top:0 ;}
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.leftData {
  display: flex;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  span {
    display: flex;
    width: 300px;
  }
}

.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: flex-end;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.el-divider--horizontal {
  margin: 13px 0;
}
.dailyRemind {
  margin-left: 30px;
  margin-top: 10px;
  .dailyRemind-1 {
    margin-bottom: 20px;
    p {
      margin-top: 10px;
    }
  }
  .dailyRemind-2 {
    p {
      margin-top: 10px;
    }
  }
}
.el-dialogs {
  .el-cascader {
    width: 100%;
  }
}
</style>
<style lang="less" scope>
.upload-btn {
  width: 5rem;
  height: 28px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
</style>
